<template>
  <div class="hardware-ids view">
    <app-header :title="'Hardware Ids'"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">{{__('common.total')}}:</div>
        <div class="control-bar__info-value">{{ pagination.total }}</div>
      </div>
      <text-field
          class="control-bar__search"
          v-model="searchTerm"
          :label="__('common.search_term')"
          max-length="50"
          :hide-details="true"
          :clearable="true"
          @keyup:enter="refreshDelayed(100)"
          @click:clear="refreshDelayed(100)"
      />
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
      </div>
    </div>
    <div class="scroll-horizontal-overflow">
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <data-table
          :records="hardwareIds"
          :fields="dataTableFields"
          :record-actions="recordActions"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :loading="loading"
          @disableHardwareId="disableHardwareId"
          @enableHardwareId="enableHardwareId"
          @deleteHardwareId="deleteHardwareId"
          @sort="sort"
      />
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
    </div>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import apiSecured from '../../api/secured';
  import DataTable from '../../components/DataTable'
  import AppHeader from '../../components/AppHeader'
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { paginationMixin } from "../../mixins/pagination";
  import { pageTitleMixin } from "../../mixins/pageTitle";

  export default {
    mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin],
    components: {
      appHeader: AppHeader,
      dataTable: DataTable,
    },
    data: () => ({
      loading: false,
      hardwareIds: [],
      dataTableFields: [],
    }),
    methods: {
      refreshDelayed(delay) {
        setTimeout(this.refresh, delay);
      },
      refresh() {
        this.loading = true;
        apiSecured.get('/dll/hardwareid', {
          params: this.getPaginationParams(),
        }).then(async (res) => {
          this.hardwareIds = res.data;
          await this.enrichRecords();
          this.updatePagination(res);
          this.loading = false;
          this.showInfoNotification('Hardware Ids have been reloaded');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async enrichRecords() {
        const userIds = this.hardwareIds.filter(record => record.userId !== null).map(record => record.userId);

        if (userIds.length > 0) {
          await apiSecured.get(`/identity/user/(${userIds.join(',')})`, {
            params: {
              fields: 'id,userName'
            },
          }).then((res) => {
            this.hardwareIds = this.hardwareIds.map(record => {
              record.user = res.data.find(resRecord => resRecord.id === record.userId);
              return record;
            });
            this.showInfoNotification('Users have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }
      },
      disableHardwareId(hardwareId) {
        Swal.fire({
          title: 'Are you sure?',
          text: "Do you really want to disable this Hardware Id?",
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, disable it!'
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            apiSecured.patch('/dll/hardwareid/' + hardwareId.id, [{
              op: 'replace',
              path: `/enabled`,
              value: false
            }]).then((res) => {
              this.loading = false;
              this.showSuccessNotification('Hardware Id has been disabled');
              this.refresh()
            }).catch((error) => {
              this.loading = false;
              this.showErrorNotifications(error);
              this.refresh()
            });
          }
        });
      },
      enableHardwareId(hardwareId) {
        Swal.fire({
          title: 'Are you sure?',
          text: "Do you really want to enable this Hardware Id?",
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, enable it!'
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            apiSecured.patch('/dll/hardwareid/' + hardwareId.id, [{
              op: 'replace',
              path: `/enabled`,
              value: true
            }]).then((res) => {
              this.loading = false;
              this.showSuccessNotification('Hardware Id has been enabled');
              this.refresh()
            }).catch((error) => {
              this.loading = false;
              this.showErrorNotifications(error);
              this.refresh()
            });
          }
        });
      },
    },
    created() {
      this.setPageTitle('Hardware Ids');
      this.dataTableFields = [
        { name: "id", title: this.__('entity.common.id'), type: "default" },
        { name: "hardwareIdString", title: 'Hardware Id', type: "default" },
        { name: "user.userName", title: 'User', type: "ref", disableSorting: true },
        { name: "enabled", title: 'Enabled', type: "boolean" },
        { name: "createdAt", title: this.__('entity.common.created_at'), type: "datetime" },
        { name: "updatedAt", title: this.__('entity.common.updated_at'), type: "datetime" }
      ];
      this.recordActions = [
        {
          title: 'Disable Hardware Id', icon: 'switch-off', event: 'enableHardwareId', vif: (record) => {
            return !record.enabled
          }
        },
        {
          title: 'Disable Hardware Id', icon: 'switch-on', event: 'disableHardwareId', vif: (record) => {
            return record.enabled
          }
        },
      ];
      this.refresh();
    }
  }
</script>
